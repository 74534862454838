import validate from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/pages/runtime/validate.js";
import stripe_45redirect_45global from "/usr/prnews/middleware/stripe-redirect.global.ts";
import manifest_45route_45rule from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  stripe_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/prnews/middleware/auth.ts"),
  "publisher-flow": () => import("/usr/prnews/middleware/publisher-flow.ts"),
  publisher: () => import("/usr/prnews/middleware/publisher.ts"),
  survey: () => import("/usr/prnews/middleware/survey.ts"),
  unauthorized: () => import("/usr/prnews/middleware/unauthorized.ts")
}