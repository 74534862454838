<script setup lang="ts">
import Modal from '~/components/organisms/Modal.vue'
import Button from '~/components/molecules/Button.vue'

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <Modal
    :open="true"
    class="!m-auto"
  >
    <template #header>
      Access Denied
    </template>
    <template #body>
      You don't have access to this resource. If you are sure that an error has occurred, contact support.
    </template>
    <Button
      class="w-full justify-center"
      @click="handleError"
    >
      Got It
    </Button>
  </Modal>
</template>
