import createClient, { type Middleware } from 'openapi-fetch'
import type { paths } from '~/src/api/schema'
import { useSignOut } from '~/composables/useSignOut'

interface IError {
  '@context': string
  '@type': string
  'hydra:description': string
  'hydra:title': string
}

export function useApi() {
  const config = useRuntimeConfig()
  const { $refreshSession } = useNuxtApp()
  const { signOut } = useSignOut()

  const accessToken = useCookie('sb-access-token')

  const apiClient = ref(
    createClient<paths>(
      {
        headers: accessToken.value
          ? {
              Authorization: `Bearer ${accessToken.value}`,
            }
          : {},
        baseUrl: config.public.apiEndpoint,
      }),
  )

  const throwOnError: Middleware = {
    async onResponse({ response }) {
      if (response.status === 403) {
        showError({
          statusCode: 403,
          statusMessage: 'Access Denied',
        })
      }
      if (response.status === 404) { return undefined }
      if (response.status >= 400) {
        const body: IError = response.headers.get('content-type')?.includes('json')
          ? await response.clone().json()
          : await response.clone().text()
        throw new Error(body['hydra:description'])
      }
      return undefined
    },
  }

  const logoutOnUnauthorized: Middleware = {
    async onResponse({ response }) {
      if (response.status !== 401) { return }
      const res = await $refreshSession()
      if (res && res.data.session && !res.error) { return }
      await signOut()
    },
  }

  const authorizationHeader: Middleware = {
    async onRequest({ request }) {
      request.headers.set('Authorization', `Bearer ${accessToken.value}`)
      return request
    },
  }

  apiClient.value.use(authorizationHeader)
  apiClient.value.use(throwOnError)
  apiClient.value.use(logoutOnUnauthorized)

  return apiClient.value
}
