import { AnalyticsBrowser } from '@segment/analytics-next'
import type { EventProperties } from '@segment/analytics-next'

declare module '#app' {
  interface NuxtApp {
    $analytics: AnalyticsBrowser | null
  }
}

type DirectiveElement = Element & {
  clickEvent: () => void
}
type DirectiveBinding = {
  arg?: string
  value?: boolean | EventProperties
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const route = useRoute()
  const getRouteBaseName = useRouteBaseName()

  const analytics = import.meta.env.PROD && config.public.segmentKey
    ? AnalyticsBrowser.load({ writeKey: config.public.segmentKey })
    : null

  analytics?.addSourceMiddleware(async ({ payload, next }) => {
    const { type } = payload.obj

    if (type === 'track') {
      const group = await analytics.group()
      const groupId = group.id()
      const path = getRouteBaseName(route)

      if (groupId) {
        payload.obj.context = {
          ...payload.obj.context,
          groupId,
        }
      }

      payload.obj.properties = {
        ...payload.obj.properties,
        page: path,
      }
    }

    next(payload)
  })

  const setElementClickHandler = (el: DirectiveElement, binding: DirectiveBinding) => {
    const { arg, value } = binding

    el.clickEvent = function () {
      if (!arg || value === false) {
        return
      }

      analytics?.track(arg, value === true ? {} : value)
    }

    el.addEventListener('click', el.clickEvent)
  }

  nuxtApp.vueApp.directive('analytics', {
    created(el, binding) {
      setElementClickHandler(el, binding)
    },
    unmounted(el) {
      el.removeEventListener('click', el.clickEvent)
    },
  })

  return {
    provide: {
      analytics,
    },
  }
})
