import revive_payload_client_6qtxxQS3bt from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5flXwEohfi from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KxruPvnm1r from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_B4uOMgMTKC from "/usr/prnews/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.32.1_vite@5.4.14_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import posthog_client_vMQepPS03X from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.32.1/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import supabase_client_8KhHha1lON from "/usr/prnews/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.32.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_lju4eFi5sZ from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ak19zyd5UQ from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ajsSIfJ9Bg from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_L7VdGazbBg from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_MNqFVinh7p from "/usr/prnews/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.32.1_typescript@5.6.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/prnews/.nuxt/components.plugin.mjs";
import prefetch_client_DAKdSy3V50 from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.19.0_rollup@4.32.1_typescript@5.6.2_vite@5.4.14/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_4LxDYykIqN from "/usr/prnews/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.6.0_rollup@4.32.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import sentry_client_H4e9FHgvKR from "/usr/prnews/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.1_@opentelemetry+instru_rryg2t4rj4lulkr7bcnxfhgium/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/prnews/.nuxt/sentry-client-config.mjs";
import directives_vdcZ40Ojiu from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.32.1/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import switch_locale_path_ssr_jd5E9igQPZ from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.32.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_udh3SSHflO from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.32.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_auth_66fnPBoWcQ from "/usr/prnews/plugins/01.auth.ts";
import segment_client_PKspGsfJUR from "/usr/prnews/plugins/segment.client.ts";
import validate_fk94mvjWUp from "/usr/prnews/plugins/validate.ts";
import vueQuery_HbXfDZmQp2 from "/usr/prnews/plugins/vueQuery.ts";
export default [
  revive_payload_client_6qtxxQS3bt,
  unhead_5flXwEohfi,
  router_KxruPvnm1r,
  _0_siteConfig_B4uOMgMTKC,
  posthog_client_vMQepPS03X,
  supabase_client_8KhHha1lON,
  payload_client_lju4eFi5sZ,
  navigation_repaint_client_ak19zyd5UQ,
  check_outdated_build_client_ajsSIfJ9Bg,
  chunk_reload_client_L7VdGazbBg,
  plugin_vue3_MNqFVinh7p,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DAKdSy3V50,
  plugin_4LxDYykIqN,
  sentry_client_H4e9FHgvKR,
  sentry_client_config_o34nk2sJbg,
  directives_vdcZ40Ojiu,
  switch_locale_path_ssr_jd5E9igQPZ,
  i18n_udh3SSHflO,
  _01_auth_66fnPBoWcQ,
  segment_client_PKspGsfJUR,
  validate_fk94mvjWUp,
  vueQuery_HbXfDZmQp2
]